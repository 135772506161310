import styled from 'styled-components/macro';

export const StdContainer = styled.div`
    border: 2px #CDCDCD solid;
    border-radius: 12px;
    width: 300px;
    height: 480px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;

    ${({active}) => ( active === true && `
        border: 2px solid #FDB603;
        transform: scale(1.1);
        box-shadow: 0px 24px 23px -10px rgba(251,175,30,0.37);
    `)};

    z-index: ${({placement}) => ( placement === 'left' || placement === 'right' ? '-10' : '10')};
    ${({placement}) => ( (placement === 'left' || placement === 'right') && 'position: relative' )};
    ${({placement}) => ( placement === 'left' && 'right: -10px')};
    ${({placement}) => ( placement === 'right' && 'left: -10px')};

    @media only screen and (max-width: 1024px) {
        ${({placement}) => ( placement === 'left' || placement === 'right' ? `max-width: 80vw; left: 0; margin-bottom: 32px; margin-top: 32px;`  : ('position: relative; max-width: 80vw;'))};
    }
`

export const BoxDivider = styled.div`
    display: flex;
    flex: 1;
    background-color: #CDCDCD;
    align-items: center;
    justify-content: center;
    ${({header}) => ( header === true ? `
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    ` : `
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    `)};

    ${({active}) => (active === true && 'background-color: #FDB603')}
`

export const DividerText = styled.p`
    color: #FFFFFF;
    ${({header}) => ( header === true ? 'text-transform: uppercase; font-size: 21px;' : 'font-size: 30px;')};
    font-weight: bold;
    margin: 0;
`

export const BoxBody = styled.div`
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    margin-left: 24px;
    flex: 7;
`

export const PowerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DFDFDF;
    flex: 2;
`

export const PowerHeader = styled.p`
    margin: 0;
    color: #2F281E;
    font-size: 16px;
`

export const PowerHeaderValue = styled.p`
    margin: 0;
    color: #6B6B6B;
    font-size: 32px;
    font-weight: bold;
    ${({active}) => active === true && `color: #2945AF;`}
`

export const DescBox = styled.div`
    display: flex;
    flex: 5;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`

export const DescSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const DescHeader = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    color: #6a6a6a;
`

export const DescName = styled.p`
    margin: 0;
    font-size: 21px;
    line-height: 32px;
    color: #6B6B6B;
    ${({active}) => active === true && `color: #2945AF; font-weight: bold;`}
`
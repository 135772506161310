import styled from 'styled-components/macro'
import bgHedaer from '../../assets/BG_header.png'

export const HeaderBackground = styled.div`
    background-image: url(${bgHedaer});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0-100px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 500px;
    padding-bottom: 24px;
`

export const SolarPVLogoContainer = styled.div`
    display: flex;
    margin-top: 48px;
    margin-bottom: 24px;
    margin-left: 48px;

    @media only screen and (max-width: 768px) {
        margin: 24px 24px 0px 24px;
        align-self: center;
    }
`

export const SolarPVLogo = styled.img`
    @media only screen and (max-width: 768px) {
        width: 50vw;
    }

    @media only screen and (max-width: 568px) {
        width: 60vw;
    }
`

export const HeaderContainer = styled.div`
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 52px 0px 0px 0px;

    @media only screen and (max-width: 1024px) {
        margin-top: 72px;
    }

    @media only screen and (max-width: 568px) {
       margin-top: 102px;
    }
`

export const HeaderText = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 42px;
    margin: 0 0 0 0;
    text-align: center;
    @media only screen and (max-width: 1024px) {
        font-size: 38px;
    }

    @media only screen and (max-width: 768px) {
        font-weight: bold;
        margin: 0 12px 0 12px;
    }

    @media only screen and (max-width: 568px) {
        font-size: 28px;
        margin: 0 8px 0 8px;
    }

`
import React, {useState, useEffect} from 'react'
import { OfferContainer, PriceInput, PriceInputContainer, Header, CalculatePriceButton, RowContainer } from './styles'
import wNumb from 'wnumb'
import { PowerHeaderValue, DescSection, BoxDivider, StdContainer, BoxBody, DividerText, PowerBox, PowerHeader, DescBox, DescHeader, DescName } from './stylesOfferStandard'

function Offer({setMonthlyEnergyPayment}) {
    const [blinkBox, setBlinkBox] = useState(false);
    const [converted, setConverted] = useState(false);

    const [priceValue, setPriceValue] = useState('');
    const [invalidInput, setInvalidInput] = useState(false)

    const numRegex = /^[0-9]/;


    var moneyFormat = wNumb({
        thousand: ' ',
    });

    useEffect(() => {
        console.log(moneyFormat.from(priceValue));
        setMonthlyEnergyPayment(parseFloat(moneyFormat.from(priceValue)))

    }, [moneyFormat, priceValue, setMonthlyEnergyPayment])

    const calculatePrice = (price) =>  {
        if ( isNaN(parseFloat(moneyFormat.from(priceValue))) ) {
            setInvalidInput(true);
        } else {

            if( converted === true) {
                setBlinkBox(true);
                setTimeout(() => {
                    setBlinkBox(false)
                }, 300);
            }

            setConverted(true);

        }
    }

    return (
    <>
        <PriceInputContainer>
            <Header>Ile miesięcznie płacisz za prąd</Header> 
            <RowContainer>
                    <PriceInput invalidInput={invalidInput} type="text" pattern="[0-9]*" name="priceInput" value={priceValue} placeholder="Podaj kwotę w PLN" onChange={(event) => { 
                        if(numRegex.test(event.target.value)) {
                            setPriceValue(moneyFormat.to(parseInt(event.target.value.replaceAll(' ', ''))))
                        } else if (event.target.value === ''){
                            setPriceValue('')
                        }
                    }} onFocus={() => {setInvalidInput(false)}}/>
                    <CalculatePriceButton className="submitPrice" onClick={() => calculatePrice(priceValue)}>
                        <p>Przelicz</p>
                    </CalculatePriceButton>
            </RowContainer>
        </PriceInputContainer>
        <OfferContainer blink={blinkBox} converted={converted}>
            <StdContainer placement={'left'}>
                <BoxDivider header={true}>
                    <DividerText header={true}>Basic</DividerText>
                </BoxDivider>

                <BoxBody>
                    <PowerBox>
                        <PowerHeader>Moc instalacji:</PowerHeader>
                        <PowerHeaderValue>6,8 kW</PowerHeaderValue>
                    </PowerBox>
                    <DescBox>
                        <DescSection>
                            <DescHeader>Inwerter</DescHeader>
                            <DescName>Solax Exe Solar 330 W</DescName>
                        </DescSection>

                        <DescSection>
                            <DescHeader>Ilość modułów</DescHeader>
                            <DescName>20</DescName>
                        </DescSection>

                        <DescSection>
                            <DescHeader>Wymagana pow. dachu</DescHeader>
                            <DescName>33,4 m²</DescName>
                        </DescSection>
                    </DescBox>
                </BoxBody>
                <BoxDivider header={false}>
                    <DividerText header={false}>33 503,58 zł</DividerText>
                </BoxDivider>
            </StdContainer>

            <StdContainer placement={'center'} active={true}>
                <BoxDivider header={true} active={true}>
                    <DividerText header={true}>Standard</DividerText>
                </BoxDivider>
                <BoxBody>
                    <PowerBox>
                        <PowerHeader>Moc instalacji:</PowerHeader>
                        <PowerHeaderValue active={true}>6,6 kW</PowerHeaderValue>
                    </PowerBox>
                    <DescBox>
                        <DescSection>
                            <DescHeader>Inwerter</DescHeader>
                            <DescName active={true}>Solax Exe Solar 330 W</DescName>
                        </DescSection>

                        <DescSection>
                            <DescHeader>Ilość modułów</DescHeader>
                            <DescName active={true}>20</DescName>
                        </DescSection>

                        <DescSection>
                            <DescHeader>Wymagana pow. dachu</DescHeader>
                            <DescName active={true}>33,4 m²</DescName>
                        </DescSection>
                    </DescBox>
                </BoxBody>
                <BoxDivider header={false} active={true}>
                    <DividerText header={false}>33 048,00 zł</DividerText>
                </BoxDivider>
            </StdContainer>

            <StdContainer placement={'right'}>
                <BoxDivider header={true}>
                    <DividerText header={true}>VIP</DividerText>
                </BoxDivider>

                <BoxBody>
                    <PowerBox>
                        <PowerHeader>Moc instalacji:</PowerHeader>
                        <PowerHeaderValue>6,8 kW</PowerHeaderValue>
                    </PowerBox>
                    <DescBox>
                        <DescSection>
                            <DescHeader>Inwerter</DescHeader>
                            <DescName>Solax Jinko 340 W</DescName>
                        </DescSection>

                        <DescSection>
                            <DescHeader>Ilość modułów</DescHeader>
                            <DescName>20</DescName>
                        </DescSection>

                        <DescSection>
                            <DescHeader>Wymagana pow. dachu</DescHeader>
                            <DescName>33,4 m²</DescName>
                        </DescSection>
                    </DescBox>
                </BoxBody>
                <BoxDivider header={false} >
                    <DividerText header={false}>34 214,48 zł</DividerText>
                </BoxDivider>
            </StdContainer>
        </OfferContainer>
    </>
    )
}

export default Offer

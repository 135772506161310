import styled from 'styled-components/macro';

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;

    @media only screen and (max-width: 1024px) {
        margin-bottom: 48px;
    }

    @media only screen and (max-width: 768px) {
        margin-bottom: 32px;
    }
`

export const MessageHeader = styled.p`
    font-size: 48px;
    margin-top: 64px;
    font-weight: bold;
    color: #29304B;

    @media only screen and (max-width: 1024px) {
        font-size: 42px;
        margin-top: 24px;
    }

    @media only screen and (max-width: 768px) {
        font-size: 38px;
        margin-top: 0px;
    }
`

export const MessageText = styled.p`
    text-align: center;
    font-size: 32px;
    max-width: 1000px;
    margin-left: 40px;
    margin-right: 40px;
    color: #29304B;

    @media only screen and (max-width: 768px) {
        font-size: 26px;
        margin-left: 18px;
        margin-right: 18px;    
    }
`

export const BackButton = styled.div`
    display: flex;
    border-radius: 35px;
    background-color: #fdb603;
    transition: 0.3s ease-in-out;
    border: none;
    color: white;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    margin-top: 48px;
    margin-bottom: 48px;
    width: 400px;
    text-align: center;
    :hover {
        background-color: #ff8800;
        transition: 0.3s ease-in-out;
    }

    @media only screen and (max-width: 1024px) {
        max-width: 70vw;
    }

    @media only screen and (max-width: 350px) {
        padding: 3px;
        border-radius: 55px;

    }

`
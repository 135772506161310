import styled from 'styled-components/macro';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const OfferImage = styled.img`
  z-index: ${({placement}) => ( placement === 'left' || placement === 'right' ? '-10' : '10')};
  ${({placement}) => ( (placement === 'left' || placement === 'right') && 'position: relative' )};
  ${({placement}) => ( placement === 'left' && 'right: -50px')};
  ${({placement}) => ( placement === 'right' && 'left: -50px')};

  @media only screen and (max-width: 1024px) {
    ${({placement}) => ( placement === 'left' || placement === 'right' ? `max-width: 50vw; left: 0; margin-bottom: 24px;`  : ('max-width: 60vw;'))};
  }
`

export const OfferContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  margin-bottom: 64px;
  filter: blur(0.25rem);
  ${({blink}) => ( blink === true && 'opacity: 0.1')};
  ${({converted}) => ( converted === true && 'filter: blur(0rem)')};

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 32px;
  }

`

export const PriceInput = styled.input`
  display: flex;
  height: 40px;
  width: 400px;
  padding: 8px;
  font-size: 24px;
  text-align: center;
  border-radius: 35px;
  background-color: #f7f7f7;
  border: 1px solid #cdcdcd;
  font-weight: bold;
  color: #7f7f7f;
  margin-right: 12px;
  box-shadow: ${({invalidInput}) => (invalidInput ? '0px 0px 10px 2px rgb(250, 26, 26)' : 'none')};

  ::placeholder {
    font-size: 18px;
    font-weight: normal;
    color: ${({invalidInput}) => (invalidInput ? 'red' : 'default')};
  }

  :-ms-input-placeholder {
      color: ${({invalidInput}) => (invalidInput ? 'red' : 'default')};
  }

  :focus {
    outline: none;
  }

  @media only screen and (max-width: 768px) {
    width: 300px;
    margin-right: 0;
    max-width: 80vw;
  }

`

export const PriceInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 64px;
`

export const Header = styled.div`
  font-size: 32px;
  margin: 0 12px 16px 12px;
  line-height: 48px;
  text-align: center;
  font-weight: bolder;
  ${({headerClass}) => (headerClass === "form" && `
    color: #FFFFFF;
    margin: 0 16px 0 16px;
    font-size: 24px;
    font-weight: bolder;
    @media only screen and (max-width: 768px) {
      line-height: 28px;
    }
  `)};

  ${({type}) => (type === "subheader" && `
    font-size: 20px;
  `)};

`

export const CalculatePriceButton = styled.div`
  display: flex;
  border-radius: 35px;
  background-color: #fdb603;
  transition: 0.3s ease-in-out;
  border: none;
  width: 184px;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
  cursor: pointer;
  
  :hover {
    background-color: #ff8800;
    transition: 0.3s ease-in-out;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 24px;
    margin-left: 0;
  }
`

export const DataAgreementContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
  max-width: 500px;
`

export const DataAgreementHeader = styled.span`
  margin: 0;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: bold;
  text-align: right !important;
`

export const DataAgreementText = styled.p`
  margin-top: 12px;
  margin-bottom: 0px;
  font-size: 12px;
  margin-right: 24px;
  text-align: justify;
  color: rgb(31,31,31);
  ${({clickable}) => clickable !== false && 'cursor: pointer;'}
`

export const FormSubmitButton = styled.button`
  border-radius: 35px;
  margin-top: 24px;
  background-color: #29304b;
  transition: 0.3s ease-in-out;
  border: none;
  width: 184px;
  height: 54px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  :hover {
    background-color: #ff8800;
    transition: 0.3s ease-in-out;
    font-size: 18px;
  }
`

export const MainForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ClauseHref = styled.a`
  color: rgb(0,0,0);
`
import React from 'react'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import SubmitScreen from '../components/body/submitScreen/SubmitScreen';

function Summary() {
    return (
        <>
            <Header headerText="Twoja zielona energia"/>
            <SubmitScreen success={true} />
            <Footer />
        </>
    )
}

export default Summary

import React from 'react'
import { BackButton, MessageContainer, MessageHeader, MessageText } from './Styles'
import { useHistory } from "react-router-dom";

function SubmitScreen({success}) {
    const browse = useHistory();
    
    return (
        <MessageContainer>
            <MessageHeader>
                {
                    success === true ? (
                        "Dziękujemy"
                    ) : (
                        "Wystąpił błąd"
                    )
                }
            </MessageHeader>
            <MessageText>
                {
                    success === true ? (
                        "Zgłoszenie zostało przyjęte. Nasz konsultant wkrótce skontaktuje się z\u00A0Państwem na podany adres e-mail lub telefon."
                    ) : (
                        "Prosimy o\u00A0ponowne przesłanie danych kontaktowych."
                    )
                }
            </MessageText>

            <BackButton onClick={() =>{browse.push('/')}}>
                <p>Przejdź do strony głównej</p>
            </BackButton>       
        </MessageContainer>
    )
}

export default SubmitScreen

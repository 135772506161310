import React from 'react'
import solarPvLogo from '../../assets/Logo_Solar PV.svg'
import { HeaderBackground, SolarPVLogoContainer, SolarPVLogo, HeaderContainer, HeaderText } from './styles';

function Header({headerText, headerTextBottom}) {
    return (
        <HeaderBackground>
                <SolarPVLogoContainer>
                    <SolarPVLogo src={solarPvLogo} alt="logo"/>
                </SolarPVLogoContainer>
                <HeaderContainer>
                    {
                        headerTextBottom !== undefined ? (
                            <>
                                <HeaderText>{headerText}<br />{headerTextBottom}</HeaderText>
                            </>
                        ) : (
                            <>
                                <HeaderText>{headerText}</HeaderText>
                            </>
                        )
                    }
                </HeaderContainer>
        </HeaderBackground>
    )
}

export default Header

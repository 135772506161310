import styled from 'styled-components/macro'

export const FooterBackgorund = styled.div`
    background-color: #29304B;
    width: 100%;
    height: 143px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 15px;

`
export const FooterLogoContainer = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
`
export const ContactElements = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    align-items: flex-start;
    color: #FFFFFF;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`
export const LeftContactElements = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`
export const Phone = styled.div`
    margin-right: 24px;

    @media only screen and (max-width: 768px) {
        margin-top: 4px;
        margin-bottom: 4px;
        margin-right: 10px;
    }
`
export const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`

export const Link = styled.a`
    color: #FFFFFF;
    text-decoration: none;
`

export const Email = styled.div`
    @media only screen and (max-width: 768px) {
        margin-top: 4px;
        margin-bottom: 4px;
        margin-right: 10px;
    }
`

export const Copyrights = styled.div`
    @media only screen and (max-width: 768px) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
`

export const Version = styled.div`
    @media only screen and (max-width: 768px) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
`
import styled from 'styled-components/macro';
import formBackground from '../../../assets/BG_orange_form.png'

export const TextInput = styled.input`
    display: flex;
    height: 40px;
    width: 430px;
    padding: 8px;
    font-size: 16px;
    text-align: center;
    border-radius: 35px;
    background-color: #ffffff;
    border: none;
    color: #8f8d99;
    margin-bottom: 6px;
    margin-top: 6px;
    outline: none;
    transition: 0.3s ease-in-out;
    box-shadow: ${({invalidInput}) => (invalidInput ? '0px 0px 10px 2px rgb(250, 26, 26)' : 'none')};

    ::placeholder {
        color: ${({invalidInput}) => (invalidInput ? 'red' : 'default')};
    }

    :-ms-input-placeholder {
        color: ${({invalidInput}) => (invalidInput ? 'red' : 'default')};
    }

    @media only screen and (max-width: 768px) {
      height: 40px;
      max-width: 90vw;
      padding: 8px;
      font-size: 16px;
      text-align: center;
      border-radius: 35px;
      margin:6px 12px 6px 12px;
    }
`

export const CheckboxInput = styled.input`
  margin-top: 16px;
  margin-right: 12px;
  min-height: 15px;
  min-width: 15px; 
  transition: 0.3s ease-in-out;
  box-shadow: ${({invalidInput}) => (invalidInput ? '0px 0px 10px 2px rgb(250, 26, 26)' : 'none')};
  ${({visible}) => visible === false && 'visibility: hidden' }
` 

export const FormBackground = styled.div`
  background-image: url(${formBackground});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 64px;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const FormHeader = styled.div`
  background-color: #29304b;
  width: 100%;
  height: 143px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`


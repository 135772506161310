import React from 'react'
import solarPvLogo from '../../assets/Logo_Solar PV.svg'
import phoneIco from '../../assets/Ico/phone.svg'
import mailIco from '../../assets/Ico/mail.svg'
import { ContactElements, Copyrights, Email, FooterBackgorund, FooterLogoContainer, Icon, LeftContactElements, Link, Phone, Version } from './styles'

function Footer() {
    return (
        <FooterBackgorund>
            <FooterLogoContainer>
                    <img src={solarPvLogo} className="solarPvLogoFooter" alt="Logo"/>
            </FooterLogoContainer>
            <ContactElements>
                <LeftContactElements>
                    <Phone>
                        <Icon src={phoneIco} alt="logo"/>
                        <Link href="tel:+48223001895">+48 22 300 18 95</Link>
                    </Phone>    
                    <Email>
                        <Icon src={mailIco} alt="logo"/>
                        <Link href="mailto:info@salesbook-app.com">info@salesbook-app.com</Link>
                    </Email>
                </LeftContactElements>
                
                <LeftContactElements>
                    <Copyrights>
                        2021 Solar PV. Wszelkie prawa zastrzeżone.&nbsp; 
                    </Copyrights>
                    <Version>
                        Wersja 1.4.0
                    </Version>
                </LeftContactElements>
            </ContactElements>
        </FooterBackgorund>
            
    )
}

export default Footer

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Index from './pages/Index'
import Error from './pages/Error'
import Summary from './pages/Summary'
import ScrollToTop from './components/partials/ScrollToTop';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" component={Index} exact />
        <Route path="/error" component={Error} exact />
        <Route path="/summary" component={Summary} exact />
      </Switch>
    </Router>
  );
}

export default App;

import React, {useState} from 'react'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Form from '../components/body/form/Form'
import Offer from '../components/body/offer/Offer'

function Index() {

    const [monthlyPayment, setMonthlyPayment] = useState(0);

    const setMonthlyEnergyPayment = (payment) => {
        setMonthlyPayment(() => {return payment})
    }
    return (
        <>
            <Header headerText="Poznaj wycenę twojej" headerTextBottom="instalacji fotowoltaicznej"/>
                <Offer setMonthlyEnergyPayment={setMonthlyEnergyPayment}/>
                <Form monthlyPayment={monthlyPayment}/>
            <Footer />
        </>
    )
}

export default Index

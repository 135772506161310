import React, { useState, useEffect } from "react";
import { ClauseHref, DataAgreementContainer, DataAgreementHeader, DataAgreementText, FormSubmitButton, Header, MainForm } from "../offer/styles";
import { CheckboxInput, FormBackground, FormHeader, TextInput } from "./styles";
import { useHistory } from "react-router-dom";
import clausePdf from '../../../assets/SolarPV_Klauzula.pdf'

function Form({monthlyPayment}) {
  const browse = useHistory();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [rodoCheck, setRodoCheck] = useState(false);
  const [newsletterCheck, setNewsletterCheck] = useState(false);
  const [clauseCheck, setClauseCheck] = useState(false);
  const [invalidInputs, setInvalidInputs] = useState({
    invalidPhoneInput: false,
    invalidAgreement: false,
    invalidNewsletterAgreement: false,
    invalidEmail: false,
    invalidClause: false
  });
  const [totalInstalationPrice, setTotalInstalationPrice] = useState(0);

  const priceForkWH = 0.65;
  
  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setRodoCheck(false);
    setNewsletterCheck(false);
    setClauseCheck(false);
  };
  
  useEffect(() => {
      setTotalInstalationPrice(() => {return Math.round((((monthlyPayment / priceForkWH) * 12 )/ 695) * 1500)})
  }, [monthlyPayment])
  
  // useEffect(() => {
  //   console.log(totalInstalationPrice);
  
  // }, [totalInstalationPrice])

  const personSave = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("id_user", "1");

    const response = await fetch(
      "https://demo-pv-pl-api.salesbook-ent.com/api/v1/persons/save",
      {

        headers:{
          "Authorization": "Basic " + btoa("PV4:3e780d0286005")
        },
        method: "POST",
        body: formData,
      }
    );

    return (await response.json()).data.id;
  };

  const threadSave = async (personId) => {
    const formData = new FormData();
    formData.append("id_customer", personId);
    formData.append("fields", '{"SOURCE":"Landing page - kampania Google Ads"}');
    formData.append("contract_value", totalInstalationPrice)

    if (monthlyPayment > 300 ) {
      formData.append("priority", "URGENT");
    }

    await fetch("https://demo-pv-pl-api.salesbook-ent.com/api/v1/threads/create", {
      headers:{
        "Authorization": "Basic " + btoa("PV4:3e780d0286005")
      },
      method: "POST",
      body: formData,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if ( !validateForm() ) {
      return;
    }

    const headers = new Headers();
    headers.set("Authorization", "Basic " + btoa("PV4:3e780d0286005"));

    try {
      const personId = await personSave();

      await threadSave(personId, headers);
    } catch (err) {
      console.log(err);
      browse.push('/error')
      return;
    }

    resetForm();
    browse.push('/summary')
  };

  const validateForm = () => {
    let isFormValid = true;
    if (!phone) {
      setInvalidInputs((prevValue) => {
        return {...prevValue, invalidPhoneInput: true}
      })
      isFormValid = false;
    } else {
      const phoneRegex = /^(\(?\+?[0-9]*\)?)?[0-9_\- ]*$/;
      if( phoneRegex.test(String(phone).toLowerCase()) === false) {
        isFormValid = false;
        setInvalidInputs((prevValue) => {
          return {...prevValue, invalidPhoneInput: true}
        })
      }
    }

    if (!rodoCheck) {
      setInvalidInputs((prevValue) => {
        return {...prevValue, invalidAgreement: true}
      })
      isFormValid = false;
    }

    if(!newsletterCheck) {
      setInvalidInputs((prevValue) => {
        return {...prevValue, invalidNewsletterAgreement: true}
      })
      isFormValid = false;
    }

    if(!clauseCheck) {
      setInvalidInputs((prevValue) => {
        return {...prevValue, invalidClause: true}
      })
      isFormValid = false;
    }

    if (email !== "") {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(regex.test(String(email).toLowerCase()) === false) {
        isFormValid = false;
        setInvalidInputs((prevValue) => {
          return {...prevValue, invalidEmail: true}
        })
        
      }
      
    }

    return isFormValid;
  }

  return (
    <>
      <FormHeader>
        <Header headerClass={"form"} >Nie czekaj!</Header>
        <Header headerClass={"form"} type={"subheader"}>
          Umów się na bezpłatną konsultację z naszymi ekspertami.
        </Header>
      </FormHeader>
      <FormBackground>
        <MainForm onSubmit={onSubmit}>
          <TextInput type="text" name="name" placeholder="Imię" maxLength="32"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextInput invalidInput={invalidInputs.invalidPhoneInput} type="tel" name="phone" value={phone}
            placeholder={`${invalidInputs.invalidPhoneInput ? "Wprowadź poprawny numer telefonu" : "Numer telefonu"}`}
            onChange={(event) => setPhone(() => event.target.value)}
            onFocus={() => {setInvalidInputs({
              ...invalidInputs,
              invalidPhoneInput: false
            })}}
          />
          <TextInput 
            type="text"
            invalidInput={invalidInputs.invalidEmail} 
            className="formInput"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)} 
            onFocus={() => {setInvalidInputs({
              ...invalidInputs,
              invalidEmail: false
            })}}        
          />
          
          <DataAgreementContainer>
            <CheckboxInput 
              type="checkbox"
              invalidInput={invalidInputs.invalidAgreement} 
              name="rodoCheck"
              checked={rodoCheck}
              onChange={(event) => {
              setRodoCheck(event.target.checked);
                setInvalidInputs((prevValue) => {return {...prevValue, invalidAgreement: false}})
              }}
            />
            <DataAgreementText onClick={() => {
                setRodoCheck((prevVal) => {return !prevVal});
                setInvalidInputs((prevValue) => {return {...prevValue, invalidAgreement: false}})
              }}>
              <DataAgreementHeader>Zgoda na przetwarzanie danych osobowych <br /></DataAgreementHeader>
              Wyrażam zgodę na przetwarzanie przez Solar PV moich danych osobowych w celu kierowania do mnie informacji marketingowej dotyczącej wybranych dla mnie ofert w zakresie zakupu instalacji fotowoltaicznej.
            </DataAgreementText>
          </DataAgreementContainer>

          <DataAgreementContainer>
            <CheckboxInput 
              type="checkbox"
              invalidInput={invalidInputs.invalidNewsletterAgreement} 
              name="newsletterCheck"
              checked={newsletterCheck}
              onChange={(event) => {
              setNewsletterCheck(event.target.checked);
                setInvalidInputs((prevValue) => {return {...prevValue, invalidNewsletterAgreement: false}})
              }}
            />
            <DataAgreementText onClick={() => {
              setNewsletterCheck((prevVal) => {return !prevVal});
              setInvalidInputs((prevValue) => {return {...prevValue, invalidNewsletterAgreement: false}})
            }}>
              <DataAgreementHeader>Zgoda na otrzymywanie informacji - e-mail od Solar&nbsp;PV <br /></DataAgreementHeader>
            Wyrażam zgodę Solar PV na informowanie mnie o ofertach Solar PV dotyczących rozwiązań w zakresie rozwiązań fotowoltaicznych za pomocą środków komunikacji elektronicznej w postaci poczty elektronicznej lub komunikatorów internetowych, w tym przesyłanie tych wiadomości e-mail lub komunikatów automatycznie, za pomocą systemów działających bez udziału człowieka.
            </DataAgreementText>
          </DataAgreementContainer>

          <DataAgreementContainer>
              <CheckboxInput 
                type="checkbox"
                name="placeholder"
                visible={false}
              />
              <div>
              <DataAgreementText clickable={false}>
                <DataAgreementHeader>Twoje dane podane na formularzu będzie przetwarzał Solar PV (Administrator danych). <br /></DataAgreementHeader>
                  Zapoznaj się z pełną treścią klauzuli informacyjnej. Znajdziesz w niej: dane kontaktowe do Inspektora Ochrony Danych, cel przetwarzania Twoich danych, Twoje prawa, odbiorcę Twoich danych. Dane podajesz nam dobrowolnie i masz prawo między innymi do ich dostępu i poprawy. <br />
                  <ClauseHref href={clausePdf} download="SolarPV - Klauzula informacyna.pdf" target="_blank">
                  Pełna treść klauzuli informacyjnej 
                  </ClauseHref>
              </DataAgreementText>
            </div>
          </DataAgreementContainer>

          <DataAgreementContainer>
            <CheckboxInput 
              type="checkbox"
              invalidInput={invalidInputs.invalidClause} 
              name="clauseCheck"
              checked={clauseCheck}
              onChange={(event) => {
                setClauseCheck(event.target.checked);
                setInvalidInputs((prevValue) => {return {...prevValue, invalidClause: false}})
              }}
            />
            <DataAgreementText onClick={() => {
              setClauseCheck((prevVal) => {return !prevVal});
              setInvalidInputs((prevValue) => {return {...prevValue, invalidClause: false}})
            }}>
              <DataAgreementHeader>Potwierdzam, że zapoznałem się z klauzulą informacyjną</DataAgreementHeader>
            </DataAgreementText>
          </DataAgreementContainer>

          <FormSubmitButton type="submit">
            Wyślij
          </FormSubmitButton>
        </MainForm>
      </FormBackground>   
    </>
  );
}
export default Form;